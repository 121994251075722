import { SHOP_TOKEN_SCRIPT } from '@/services/Configuration'
import Script from 'next/script'
import React from 'react'

const ShopTokenScript: React.FC = () => (
  <Script
    strategy="lazyOnload"
    type="application/javascript"
    src={SHOP_TOKEN_SCRIPT}
  />
)

export default ShopTokenScript
